import { default as indexGnqeV2ZHBLMeta } from "/opt/apps/.build/apps/web/app/pages/about-us/index.vue?macro=true";
import { default as indexwu1q0qnZdoMeta } from "/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue?macro=true";
import { default as indexvMGYyH2lG5Meta } from "/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue?macro=true";
import { default as indexw7ZV3iZwBdMeta } from "/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue?macro=true";
import { default as indexjQ0wL5DR5zMeta } from "/opt/apps/.build/apps/web/app/pages/business/index.vue?macro=true";
import { default as _91token_93apbrwjeqmnMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue?macro=true";
import { default as indexhjsSujXxn7Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue?macro=true";
import { default as _91code_936MhlyScz2zMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue?macro=true";
import { default as index950K5k0DHGMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue?macro=true";
import { default as indexuxIZCcDCljMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue?macro=true";
import { default as indexqkNzWNBKgcMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue?macro=true";
import { default as indexjhQHxFTNhxMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue?macro=true";
import { default as sign_45outFI17sBYw4bMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue?macro=true";
import { default as indexqdT2VB2zXhMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue?macro=true";
import { default as dashboardDQduFRXX37Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue?macro=true";
import { default as indexAcpFwjW6WMMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue?macro=true";
import { default as indexw9xgASoMyPMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue?macro=true";
import { default as indexCQtCMgFsaaMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue?macro=true";
import { default as payIQATXowjWtMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue?macro=true";
import { default as checkout7ScQEVfVtxMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue?macro=true";
import { default as indexa3c8kUjm3QMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue?macro=true";
import { default as indexTC8lr75bZHMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue?macro=true";
import { default as dashboardfMpF22Dt0pMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue?macro=true";
import { default as activationbtKGvXyKMEMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue?macro=true";
import { default as payeu8dMxqna0Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue?macro=true";
import { default as checkout5bal2VtYqeMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue?macro=true";
import { default as indexyUhCLKJqkAMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue?macro=true";
import { default as indexrAZMFHCNIQMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue?macro=true";
import { default as dashboardfzm70OO0oNMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue?macro=true";
import { default as indexnk30GuiXlkMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue?macro=true";
import { default as room1VrmeeYJMaMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue?macro=true";
import { default as confirmzrKuDy5VspMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue?macro=true";
import { default as rescheduley0Xik6hLmOMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue?macro=true";
import { default as reviewegon8COXRrMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue?macro=true";
import { default as calendar2BVAFPQWGOMeta } from "/opt/apps/.build/apps/web/layers/psychologist/pages/cabinet/psychologist/calendar.vue?macro=true";
import { default as dashboardgjXAAV0QxcMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue?macro=true";
import { default as indexbKrxEjr0WoMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue?macro=true";
import { default as indexM5pJTer7uKMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue?macro=true";
import { default as indexgYNnwH9mhrMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue?macro=true";
import { default as indexearmxnSW74Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue?macro=true";
import { default as indexrANlcaGzIkMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue?macro=true";
import { default as indexTE0oIYlTfIMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue?macro=true";
import { default as indexqT9fQ4biE7Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue?macro=true";
import { default as indexKdqP0kfeJ1Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue?macro=true";
import { default as checkoutfQlgwIC8EWMeta } from "/opt/apps/.build/apps/web/app/pages/carts/[id]/checkout.vue?macro=true";
import { default as index9ZDfzXUde1Meta } from "/opt/apps/.build/apps/web/app/pages/contacts/index.vue?macro=true";
import { default as indexUDKTAnHz0RMeta } from "/opt/apps/.build/apps/web/app/pages/emergency/index.vue?macro=true";
import { default as indexDQzhMz3lQPMeta } from "/opt/apps/.build/apps/web/app/pages/family/index.vue?macro=true";
import { default as index3kNQOa4RUqMeta } from "/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue?macro=true";
import { default as join_45usvzLxr06FAfMeta } from "/opt/apps/.build/apps/web/layers/psychologist/pages/for-psychologists/join-us.vue?macro=true";
import { default as indexXoOQI5DaMsMeta } from "/opt/apps/.build/apps/web/app/pages/index.vue?macro=true";
import { default as indexITWAFCLP3hMeta } from "/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue?macro=true";
import { default as indexE5E35YQf7QMeta } from "/opt/apps/.build/apps/web/app/pages/kids/index.vue?macro=true";
import { default as bookingBfCXOC7Uv3Meta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue?macro=true";
import { default as category8bqVYRZoNEMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue?macro=true";
import { default as childhood_ageQI7UyFsaMBMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue?macro=true";
import { default as finishedVMKbZ5UomkMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue?macro=true";
import { default as initialGRVIvrzbpHMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue?macro=true";
import { default as psychologisteL3jaEITLpMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue?macro=true";
import { default as requestsRV80EPlnslMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue?macro=true";
import { default as indexkpu9osV6TLMeta } from "/opt/apps/.build/apps/web/app/pages/matching/index.vue?macro=true";
import { default as successicrXSmHQ0iMeta } from "/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue?macro=true";
import { default as _91slug_93GwtDJylv5IMeta } from "/opt/apps/.build/apps/web/app/pages/pages/[slug].vue?macro=true";
import { default as tech_45problems4auPv3oAUCMeta } from "/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue?macro=true";
import { default as _91payload_93FlG44H0AgkMeta } from "/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue?macro=true";
import { default as _91payload_93Pw3TbNnXlbMeta } from "/opt/apps/.build/apps/web/app/pages/pay/platon/[payload].vue?macro=true";
import { default as _91payload_93EGN54YiNlKMeta } from "/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue?macro=true";
import { default as index0LW3gAcR7MMeta } from "/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue?macro=true";
import { default as indexGPaXbRFSUxMeta } from "/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue?macro=true";
import { default as registeruNpdRfmGrmMeta } from "/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue?macro=true";
import { default as _91slug_93hc2ieDyy50Meta } from "/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue?macro=true";
import { default as indexD9QpfRa9f8Meta } from "/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue?macro=true";
import { default as indexIHjeocge8rMeta } from "/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/index.vue?macro=true";
import { default as resultsKfTAcHhP49Meta } from "/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/results.vue?macro=true";
import { default as indexksRghu3vUpMeta } from "/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/index.vue?macro=true";
export default [
  {
    name: "about-us___uk",
    path: "/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___es",
    path: "/es/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___ru",
    path: "/ru/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category___uk",
    path: "/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category___en",
    path: "/en/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category___es",
    path: "/es/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category___ru",
    path: "/ru/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category-post___uk",
    path: "/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category-post___en",
    path: "/en/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category-post___es",
    path: "/es/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-category-post___ru",
    path: "/ru/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: "business-customer-register___uk",
    path: "/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: "business-customer-register___en",
    path: "/en/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: "business-customer-register___es",
    path: "/es/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: "business-customer-register___ru",
    path: "/ru/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: "business___uk",
    path: "/business",
    meta: indexjQ0wL5DR5zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: "business___en",
    path: "/en/business",
    meta: indexjQ0wL5DR5zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: "business___es",
    path: "/es/business",
    meta: indexjQ0wL5DR5zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: "business___ru",
    path: "/ru/business",
    meta: indexjQ0wL5DR5zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-email-confirm-request-token___uk",
    path: "/cabinet/auth/email-confirm/:request()/:token()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-email-confirm-request-token___en",
    path: "/en/cabinet/auth/email-confirm/:request()/:token()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-email-confirm-request-token___es",
    path: "/es/cabinet/auth/email-confirm/:request()/:token()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-email-confirm-request-token___ru",
    path: "/ru/cabinet/auth/email-confirm/:request()/:token()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-finish-registration___uk",
    path: "/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-finish-registration___en",
    path: "/en/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-finish-registration___es",
    path: "/es/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-finish-registration___ru",
    path: "/ru/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password-code___uk",
    path: "/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password-code___en",
    path: "/en/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password-code___es",
    path: "/es/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password-code___ru",
    path: "/ru/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password___uk",
    path: "/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password___en",
    path: "/en/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password___es",
    path: "/es/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-reset-password___ru",
    path: "/ru/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-flowId-magicToken___uk",
    path: "/cabinet/auth/sign-in/:flowId()/:magicToken()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-flowId-magicToken___en",
    path: "/en/cabinet/auth/sign-in/:flowId()/:magicToken()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-flowId-magicToken___es",
    path: "/es/cabinet/auth/sign-in/:flowId()/:magicToken()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-flowId-magicToken___ru",
    path: "/ru/cabinet/auth/sign-in/:flowId()/:magicToken()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in___uk",
    path: "/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in___en",
    path: "/en/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in___es",
    path: "/es/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in___ru",
    path: "/ru/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-oauth___uk",
    path: "/cabinet/auth/sign-in/oauth",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-oauth___en",
    path: "/en/cabinet/auth/sign-in/oauth",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-oauth___es",
    path: "/es/cabinet/auth/sign-in/oauth",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-in-oauth___ru",
    path: "/ru/cabinet/auth/sign-in/oauth",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-out___uk",
    path: "/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-out___en",
    path: "/en/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-out___es",
    path: "/es/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-out___ru",
    path: "/ru/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-up___uk",
    path: "/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-up___en",
    path: "/en/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-up___es",
    path: "/es/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-auth-sign-up___ru",
    path: "/ru/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-business-dashboard___uk",
    path: "/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-business-dashboard___en",
    path: "/en/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-business-dashboard___es",
    path: "/es/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-business-dashboard___ru",
    path: "/ru/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-calendar___uk",
    path: "/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-calendar___en",
    path: "/en/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-calendar___es",
    path: "/es/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-calendar___ru",
    path: "/ru/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat-roomId___uk",
    path: "/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat-roomId___en",
    path: "/en/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat-roomId___es",
    path: "/es/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat-roomId___ru",
    path: "/ru/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat___uk",
    path: "/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat___en",
    path: "/en/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat___es",
    path: "/es/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-chat___ru",
    path: "/ru/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-carts-id-pay___uk",
    path: "/cabinet/customer/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-carts-id-pay___en",
    path: "/en/cabinet/customer/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-carts-id-pay___es",
    path: "/es/cabinet/customer/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-carts-id-pay___ru",
    path: "/ru/cabinet/customer/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId-checkout___uk",
    path: "/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId-checkout___en",
    path: "/en/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId-checkout___es",
    path: "/es/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId-checkout___ru",
    path: "/ru/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId___uk",
    path: "/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId___en",
    path: "/en/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId___es",
    path: "/es/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog-itemId___ru",
    path: "/ru/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog___uk",
    path: "/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog___en",
    path: "/en/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog___es",
    path: "/es/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-catalog___ru",
    path: "/ru/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-dashboard___uk",
    path: "/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-dashboard___en",
    path: "/en/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-dashboard___es",
    path: "/es/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-customer-dashboard___ru",
    path: "/ru/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-activation___uk",
    path: "/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-activation___en",
    path: "/en/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-activation___es",
    path: "/es/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-activation___ru",
    path: "/ru/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-carts-id-pay___uk",
    path: "/cabinet/employee/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-carts-id-pay___en",
    path: "/en/cabinet/employee/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-carts-id-pay___es",
    path: "/es/cabinet/employee/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-carts-id-pay___ru",
    path: "/ru/cabinet/employee/carts/:id()/pay",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId-checkout___uk",
    path: "/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId-checkout___en",
    path: "/en/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId-checkout___es",
    path: "/es/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId-checkout___ru",
    path: "/ru/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId___uk",
    path: "/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId___en",
    path: "/en/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId___es",
    path: "/es/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog-itemId___ru",
    path: "/ru/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog___uk",
    path: "/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog___en",
    path: "/en/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog___es",
    path: "/es/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-catalog___ru",
    path: "/ru/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-dashboard___uk",
    path: "/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-dashboard___en",
    path: "/en/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-dashboard___es",
    path: "/es/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-employee-dashboard___ru",
    path: "/ru/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId___uk",
    path: "/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId___en",
    path: "/en/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId___es",
    path: "/es/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId___ru",
    path: "/ru/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId-room___uk",
    path: "/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId-room___en",
    path: "/en/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId-room___es",
    path: "/es/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-meetings-meetingId-room___ru",
    path: "/ru/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-orderId-confirm___uk",
    path: "/cabinet/orders/:orderId()/confirm",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-orderId-confirm___en",
    path: "/en/cabinet/orders/:orderId()/confirm",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-orderId-confirm___es",
    path: "/es/cabinet/orders/:orderId()/confirm",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-orderId-confirm___ru",
    path: "/ru/cabinet/orders/:orderId()/confirm",
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-reschedule___uk",
    path: "/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-reschedule___en",
    path: "/en/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-reschedule___es",
    path: "/es/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-reschedule___ru",
    path: "/ru/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-review___uk",
    path: "/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-review___en",
    path: "/en/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-review___es",
    path: "/es/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders-sessions-sessionId-review___ru",
    path: "/ru/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-calendar___uk",
    path: "/cabinet/psychologist/calendar",
    meta: calendar2BVAFPQWGOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-calendar___en",
    path: "/en/cabinet/psychologist/calendar",
    meta: calendar2BVAFPQWGOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-calendar___es",
    path: "/es/cabinet/psychologist/calendar",
    meta: calendar2BVAFPQWGOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-calendar___ru",
    path: "/ru/cabinet/psychologist/calendar",
    meta: calendar2BVAFPQWGOMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-dashboard___uk",
    path: "/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-dashboard___en",
    path: "/en/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-dashboard___es",
    path: "/es/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-dashboard___ru",
    path: "/ru/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-google-calendars___uk",
    path: "/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-google-calendars___en",
    path: "/en/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-google-calendars___es",
    path: "/es/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-google-calendars___ru",
    path: "/ru/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-my-customers___uk",
    path: "/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-my-customers___en",
    path: "/en/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-my-customers___es",
    path: "/es/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-my-customers___ru",
    path: "/ru/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payment-methods-add-method___uk",
    path: "/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payment-methods-add-method___en",
    path: "/en/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payment-methods-add-method___es",
    path: "/es/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payment-methods-add-method___ru",
    path: "/ru/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts-add-method___uk",
    path: "/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts-add-method___en",
    path: "/en/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts-add-method___es",
    path: "/es/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts-add-method___ru",
    path: "/ru/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts___uk",
    path: "/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts___en",
    path: "/en/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts___es",
    path: "/es/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-payouts___ru",
    path: "/ru/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-profile___uk",
    path: "/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-profile___en",
    path: "/en/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-profile___es",
    path: "/es/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-profile___ru",
    path: "/ru/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-suggestions-chat-roomId___uk",
    path: "/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-suggestions-chat-roomId___en",
    path: "/en/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-suggestions-chat-roomId___es",
    path: "/es/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-psychologist-suggestions-chat-roomId___ru",
    path: "/ru/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-settings___uk",
    path: "/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-settings___en",
    path: "/en/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-settings___es",
    path: "/es/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-settings___ru",
    path: "/ru/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "carts-id-checkout___uk",
    path: "/carts/:id()/checkout",
    component: () => import("/opt/apps/.build/apps/web/app/pages/carts/[id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "carts-id-checkout___en",
    path: "/en/carts/:id()/checkout",
    component: () => import("/opt/apps/.build/apps/web/app/pages/carts/[id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "carts-id-checkout___es",
    path: "/es/carts/:id()/checkout",
    component: () => import("/opt/apps/.build/apps/web/app/pages/carts/[id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "carts-id-checkout___ru",
    path: "/ru/carts/:id()/checkout",
    component: () => import("/opt/apps/.build/apps/web/app/pages/carts/[id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "contacts___uk",
    path: "/contacts",
    meta: index9ZDfzXUde1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts___en",
    path: "/en/contacts",
    meta: index9ZDfzXUde1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts___es",
    path: "/es/contacts",
    meta: index9ZDfzXUde1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts___ru",
    path: "/ru/contacts",
    meta: index9ZDfzXUde1Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "emergency___uk",
    path: "/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: "emergency___en",
    path: "/en/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: "emergency___es",
    path: "/es/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: "emergency___ru",
    path: "/ru/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: "family___uk",
    path: "/family",
    meta: indexDQzhMz3lQPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: "family___en",
    path: "/en/family",
    meta: indexDQzhMz3lQPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: "family___es",
    path: "/es/family",
    meta: indexDQzhMz3lQPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: "family___ru",
    path: "/ru/family",
    meta: indexDQzhMz3lQPMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists___uk",
    path: "/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists___en",
    path: "/en/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists___es",
    path: "/es/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists___ru",
    path: "/ru/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists-join-us___uk",
    path: "/for-psychologists/join-us",
    meta: join_45usvzLxr06FAfMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/for-psychologists/join-us.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists-join-us___en",
    path: "/en/for-psychologists/join-us",
    meta: join_45usvzLxr06FAfMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/for-psychologists/join-us.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists-join-us___es",
    path: "/es/for-psychologists/join-us",
    meta: join_45usvzLxr06FAfMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/for-psychologists/join-us.vue").then(m => m.default || m)
  },
  {
    name: "for-psychologists-join-us___ru",
    path: "/ru/for-psychologists/join-us",
    meta: join_45usvzLxr06FAfMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/psychologist/pages/for-psychologists/join-us.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/",
    meta: indexXoOQI5DaMsMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexXoOQI5DaMsMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexXoOQI5DaMsMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexXoOQI5DaMsMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id___uk",
    path: "/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id___en",
    path: "/en/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id___es",
    path: "/es/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id___ru",
    path: "/ru/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "kids___uk",
    path: "/kids",
    meta: indexE5E35YQf7QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: "kids___en",
    path: "/en/kids",
    meta: indexE5E35YQf7QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: "kids___es",
    path: "/es/kids",
    meta: indexE5E35YQf7QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: "kids___ru",
    path: "/ru/kids",
    meta: indexE5E35YQf7QMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-booking___uk",
    path: "/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-booking___en",
    path: "/en/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-booking___es",
    path: "/es/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-booking___ru",
    path: "/ru/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-category___uk",
    path: "/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-category___en",
    path: "/en/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-category___es",
    path: "/es/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-category___ru",
    path: "/ru/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-childhood_age___uk",
    path: "/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-childhood_age___en",
    path: "/en/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-childhood_age___es",
    path: "/es/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-childhood_age___ru",
    path: "/ru/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-finished___uk",
    path: "/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-finished___en",
    path: "/en/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-finished___es",
    path: "/es/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-finished___ru",
    path: "/ru/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-initial___uk",
    path: "/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-initial___en",
    path: "/en/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-initial___es",
    path: "/es/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-initial___ru",
    path: "/ru/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-psychologist___uk",
    path: "/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-psychologist___en",
    path: "/en/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-psychologist___es",
    path: "/es/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-psychologist___ru",
    path: "/ru/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-requests___uk",
    path: "/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-requests___en",
    path: "/en/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-requests___es",
    path: "/es/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: "matching-id-requests___ru",
    path: "/ru/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: "matching___uk",
    path: "/matching",
    meta: indexkpu9osV6TLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: "matching___en",
    path: "/en/matching",
    meta: indexkpu9osV6TLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: "matching___es",
    path: "/es/matching",
    meta: indexkpu9osV6TLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: "matching___ru",
    path: "/ru/matching",
    meta: indexkpu9osV6TLMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-orderId-success___uk",
    path: "/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-orderId-success___en",
    path: "/en/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-orderId-success___es",
    path: "/es/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-orderId-success___ru",
    path: "/ru/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: "pages-slug___uk",
    path: "/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pages-slug___en",
    path: "/en/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pages-slug___es",
    path: "/es/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pages-slug___ru",
    path: "/ru/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pages-tech-problems___uk",
    path: "/pages/tech-problems",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: "pages-tech-problems___en",
    path: "/en/pages/tech-problems",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: "pages-tech-problems___es",
    path: "/es/pages/tech-problems",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: "pages-tech-problems___ru",
    path: "/ru/pages/tech-problems",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: "pay-liqpay-payload___uk",
    path: "/pay/liqpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-liqpay-payload___en",
    path: "/en/pay/liqpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-liqpay-payload___es",
    path: "/es/pay/liqpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-liqpay-payload___ru",
    path: "/ru/pay/liqpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-platon-payload___uk",
    path: "/pay/platon/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/platon/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-platon-payload___en",
    path: "/en/pay/platon/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/platon/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-platon-payload___es",
    path: "/es/pay/platon/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/platon/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-platon-payload___ru",
    path: "/ru/pay/platon/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/platon/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-wayforpay-payload___uk",
    path: "/pay/wayforpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-wayforpay-payload___en",
    path: "/en/pay/wayforpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-wayforpay-payload___es",
    path: "/es/pay/wayforpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "pay-wayforpay-payload___ru",
    path: "/ru/pay/wayforpay/:payload()",
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "psychologists-filter___uk",
    path: "/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-filter___en",
    path: "/en/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-filter___es",
    path: "/es/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-filter___ru",
    path: "/ru/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug___uk",
    path: "/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug___en",
    path: "/en/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug___es",
    path: "/es/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug___ru",
    path: "/ru/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug-register___uk",
    path: "/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug-register___en",
    path: "/en/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug-register___es",
    path: "/es/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-checkout-slug-register___ru",
    path: "/ru/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: "psychologists-view-slug___uk",
    path: "/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: "psychologists-view-slug___en",
    path: "/en/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: "psychologists-view-slug___es",
    path: "/es/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: "psychologists-view-slug___ru",
    path: "/ru/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: "russkiykorabl-idinahui___uk",
    path: "/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  },
  {
    name: "russkiykorabl-idinahui___en",
    path: "/en/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  },
  {
    name: "russkiykorabl-idinahui___es",
    path: "/es/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  },
  {
    name: "russkiykorabl-idinahui___ru",
    path: "/ru/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id___uk",
    path: "/wellbeing/:id()",
    meta: indexIHjeocge8rMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id___en",
    path: "/en/wellbeing/:id()",
    meta: indexIHjeocge8rMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id___es",
    path: "/es/wellbeing/:id()",
    meta: indexIHjeocge8rMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id___ru",
    path: "/ru/wellbeing/:id()",
    meta: indexIHjeocge8rMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id-results___uk",
    path: "/wellbeing/:id()/results",
    meta: resultsKfTAcHhP49Meta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/results.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id-results___en",
    path: "/en/wellbeing/:id()/results",
    meta: resultsKfTAcHhP49Meta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/results.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id-results___es",
    path: "/es/wellbeing/:id()/results",
    meta: resultsKfTAcHhP49Meta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/results.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing-id-results___ru",
    path: "/ru/wellbeing/:id()/results",
    meta: resultsKfTAcHhP49Meta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/[id]/results.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing___uk",
    path: "/wellbeing",
    meta: indexksRghu3vUpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing___en",
    path: "/en/wellbeing",
    meta: indexksRghu3vUpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing___es",
    path: "/es/wellbeing",
    meta: indexksRghu3vUpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/index.vue").then(m => m.default || m)
  },
  {
    name: "wellbeing___ru",
    path: "/ru/wellbeing",
    meta: indexksRghu3vUpMeta || {},
    component: () => import("/opt/apps/.build/apps/web/layers/marketplace/pages/wellbeing/index.vue").then(m => m.default || m)
  }
]