import {AsyncContainerModule, interfaces} from "inversify";
import {marketplaceTypes} from "./types";
import {CatalogRepository, ScheduleRepository} from "../features/catalog/domain";
import {B2BCartService, B2CCartService, CartRepository} from "../features/cart/domain";
import {ApiCatalogRepository, ApiScheduleRepository} from "../features/catalog/infrastructure";
import {ApiCartRepository, ApiB2BCartService, ApiB2CCartService} from "../features/cart/infrastructure";
import {FilterCatalogUseCase, GetCatalogItemUseCase, GetScheduleUseCase} from "../features/catalog/application";
import {
  AddToCartUseCase, B2BCartUseCase, B2CCartUseCase,
  CheckoutCartUseCase,
  CreateCartUseCase,
  GetCartUseCase,
  RemoveFromCartUseCase
} from "../features/cart/application";
import {OrderService, RescheduleService, SessionService} from "../features/order/domain";
import {ApiOrderService, ApiRescheduleService, ApiSessionService} from "../features/order/infrastructure";
import {
  CancelSessionUseCase, ConfirmOrderUseCase,
  GetOrderUseCase,
  GetSessionUseCase,
  RescheduleUseCase
} from "../features/order/application";
import {MatchingUseCase} from "../features/matching/application";
import {MatchingService} from "../features/matching/domain";
import {ApiMatchingService} from "../features/matching/infrastructure";
import {ReviewsService} from "../features/reviews/domain";
import {ApiReviewsService} from "../features/reviews/infrastructure";
import {CustomerReviewUseCase} from "../features/reviews/application";
import {MyPsychologistsService} from "../features/my-psychologists/domain";
import {ApiMyPsychologistsService} from "../features/my-psychologists/infrastructure";
import {GetMyPsychologistsUseCase} from "../features/my-psychologists/application";
import {SendMessageUseCase} from "../features/messages/application";
import {PsychologistMessagesService} from "../features/messages/domain";
import {ApiPsychologistMessagesService} from "../features/messages/infrastructure/apiPsychologistMessagesService";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<CatalogRepository>(marketplaceTypes.CatalogRepository).to(ApiCatalogRepository);
  bind<ScheduleRepository>(marketplaceTypes.ScheduleRepository).to(ApiScheduleRepository);
  bind<CartRepository>(marketplaceTypes.CartRepository).to(ApiCartRepository);
  bind<B2BCartService>(marketplaceTypes.B2BCartService).to(ApiB2BCartService);
  bind<B2CCartService>(marketplaceTypes.B2CCartService).to(ApiB2CCartService);
  bind<OrderService>(marketplaceTypes.OrderService).to(ApiOrderService);
  bind<SessionService>(marketplaceTypes.SessionService).to(ApiSessionService);
  bind<RescheduleService>(marketplaceTypes.RescheduleService).to(ApiRescheduleService);

  bind<FilterCatalogUseCase>(marketplaceTypes.FilterCatalog).to(FilterCatalogUseCase);
  bind<GetCatalogItemUseCase>(marketplaceTypes.GetCatalogItem).to(GetCatalogItemUseCase);
  bind<GetScheduleUseCase>(marketplaceTypes.GetSchedule).to(GetScheduleUseCase);
  bind<RescheduleUseCase>(marketplaceTypes.RescheduleUseCase).to(RescheduleUseCase);

  bind<B2BCartUseCase>(marketplaceTypes.B2BCartUseCase).to(B2BCartUseCase);
  bind<B2CCartUseCase>(marketplaceTypes.B2CCartUseCase).to(B2CCartUseCase);
  bind<GetOrderUseCase>(marketplaceTypes.GetOrderUseCase).to(GetOrderUseCase);
  bind<GetSessionUseCase>(marketplaceTypes.GetSessionUseCase).to(GetSessionUseCase);
  bind<CancelSessionUseCase>(marketplaceTypes.CancelSessionUseCase).to(CancelSessionUseCase);
  bind<ConfirmOrderUseCase>(marketplaceTypes.ConfirmOrderUseCase).to(ConfirmOrderUseCase);

  bind<CreateCartUseCase>(marketplaceTypes.CreateCart).to(CreateCartUseCase);
  bind<GetCartUseCase>(marketplaceTypes.GetCart).to(GetCartUseCase);
  bind<AddToCartUseCase>(marketplaceTypes.AddToCart).to(AddToCartUseCase);
  bind<RemoveFromCartUseCase>(marketplaceTypes.RemoveFromCart).to(RemoveFromCartUseCase);
  bind<CheckoutCartUseCase>(marketplaceTypes.CheckoutCart).to(CheckoutCartUseCase);

  bind<MatchingUseCase>(marketplaceTypes.MatchingUseCase).to(MatchingUseCase);
  bind<MatchingService>(marketplaceTypes.MatchingService).to(ApiMatchingService);

  bind<CustomerReviewUseCase>(marketplaceTypes.CustomerReviewUseCase).to(CustomerReviewUseCase);
  bind<ReviewsService>(marketplaceTypes.ReviewsService).to(ApiReviewsService);

  bind<GetMyPsychologistsUseCase>(marketplaceTypes.GetMyPsychologistsUseCase).to(GetMyPsychologistsUseCase);
  bind<MyPsychologistsService>(marketplaceTypes.MyPsychologistsService).to(ApiMyPsychologistsService);

  bind<SendMessageUseCase>(marketplaceTypes.SendMessageUseCase).to(SendMessageUseCase);
  bind<PsychologistMessagesService>(marketplaceTypes.PsychologistMessagesService).to(ApiPsychologistMessagesService);
});
