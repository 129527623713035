import {inject, injectable} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {CatalogRepository} from "../domain";
import {CatalogItem} from "../../../models/catalog";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";

@injectable()
export class GetCatalogItemUseCase {
  constructor(
    @inject(marketplaceTypes.CatalogRepository) private readonly catalogRepository: CatalogRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async execute(catalogItemId: string): Promise<CatalogItem> {
    const response = await this.catalogRepository.fetchCatalogItem(catalogItemId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.error.code === 300) {
        throw {redirect: response.error.url};
      } else {
        this.notifierService.notifyFromHttpError(response.error);
        throw response.error;
      }
    }
  }

  async getGtmEventData(catalogItemId: string): Promise<object> {
    const response = await this.catalogRepository.fetchCatalogItemForGTM(catalogItemId);
    if (response.isOk()) {
      return response.value;
    }
    if (response.isErr()) {
      throw response.error;
    }
  }
}
